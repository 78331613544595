import React from 'react';
import { Modal } from 'antd';

const OrderStatsModal = ({ allStats, labels, isStatsOpen, statsCloseModal }) => {
  return (
    <Modal
      open={isStatsOpen}
      title="Orders stats"
      width={800}
      onOk={statsCloseModal}
    >
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th width={10}>Format</th>
            <th width={15}>Total Orders</th>
            <th width={15}>Created Orders</th>
            <th width={15}>Failed Orders</th>
            <th width={5}>Avg Weight</th>
            <th width={40}>Error</th>
          </tr>
        </thead>
        <tbody>
          {allStats && allStats !== '' && 
            Object.keys(allStats).map(format => (
              <tr key={format}>
                <td>{format}</td>
                <td>{allStats[format].total}</td>
                <td>{allStats[format].created}</td>
                <td>{allStats[format].failed}</td>
                <td>
                  {allStats[format].avgWeight.length > 0 
                    ? allStats[format].avgWeight.join(', ')
                    : 'Nill'}
                </td>
                <td>
                  {allStats[format].error !== '' ? (
                    <span>{allStats[format].error}</span>
                  ) : (
                    <span>No errors</span>
                  )}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      
      {/* Display labels as links */}
      {labels && labels.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <h2>Labels: If not opened automatically, please use the following links to open</h2>
          <ul>
            {labels.map((label, index) => (
              <li key={index}>
                <a href={label} target="_blank" rel="noopener noreferrer">
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default OrderStatsModal;
