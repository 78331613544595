import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import { get, post, postFile } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import ProductTable from "./ProductTable";
import Pagination from '../../components/Pagination';
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";
import usePermissionCheck from "../../hooks/usePermissionCheck";

const ProductList = () => {
  const canEditPro= usePermissionCheck(['Product Edit']);
  const canDeletePro = usePermissionCheck(['Product Delete']);
  const canAssignQuestion = usePermissionCheck(['Assign Question']);
  const [isLoading, setIsLoading] = useState(true);
  const [isEbayCalling, setIsEbayCalling] = useState(false);
  const [isAmazonCalling, setIsAmazonCalling] = useState(false);
  const [ebayText, setEbayText] = useState('eBay Call');
  const [amazonText, setAmazonText] = useState('Amazon Call');
  const [isNotFound, setNotFound] = useState(false); 
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [file, setFile] = useState(null);
  const [isFileSubmit, setIsFileSubmit] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filters, setFilters] = useState({
      name: '',
      status: 'All',
      type: 'All',
      platform:'All'
  });

  useEffect(() => {
    const loadData = async () => {
        setIsLoading(true);
        try {
            await fetchData();
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false); // Set to false to indicate loading is complete
        }
    };

    loadData();
}, [currentPage]);


useEffect(() => {
  fetchData();
}, [filters]);

const fetchData = async () => {
    try {
        const Body = {
            page: currentPage,
            limit: limit,
            ...filters
        };
        const res = await post(`api/products/list`, Body);
        if (res.success) {
            setProducts(res.products);
            setTotalPages(res.totalPages);
            setNotFound(false);
        } else {
            setProducts([]);
            setTotalPages(1);
            setNotFound(true);
        }
    } catch (error) {
        setProducts([]);
        setTotalPages(1);
        setNotFound(true);
        console.error(error.message);
    }
};


const EbayGetItems = async () => {
    try {
      setIsEbayCalling(true);
      setEbayText('eBay Calling...');
      const res = await get('api/products/ebay/items');
      setIsEbayCalling(false);
      setEbayText('eBay Call');
      if (res.success) {
        toast.success('Products fetched successfully');
        fetchData();
      } else {
       console.log(res)
      }

    } catch (error) {
        setIsEbayCalling(false);
        console.log(error)
    }
};

const AmazonGetItems = async () => {
  try {
    setIsAmazonCalling(true);
    setEbayText('Amazon Calling...');
    const res = await get('api/products/amazon/items');
    setIsAmazonCalling(false);
    setEbayText('Amazon Call');
    if (res.success) {
      toast.success('Products fetched successfully');
      fetchData();
    } else {
     console.log(res)
    }

  } catch (error) {
    setIsAmazonCalling(false);
      console.log(error)
  }
};

const handlePageChange = (page) => {
    setCurrentPage(page);
};

const handleFilterChange = (e) => {
    setFilters({
        ...filters,
        [e.target.name]: e.target.value
    });
};

const handleCheckboxChange = (e) => {
  const { value, checked } = e.target;
  if (checked) {
    setSelectedProducts((prev) => [...prev, value]);
  } else {
    setSelectedProducts((prev) => prev.filter((id) => id !== value));
  }
};

const handleBulkCheckboxChange = (e) => {
  if (e.target.checked) {
    const allProductIds = products.map(product => product._id);
    setSelectedProducts(allProductIds);
  } else {
    setSelectedProducts([]);
  }
};

const handleBulkAction = (value) => {
  if(value === 'P-Med' || value === 'GSL'){
    if(selectedProducts && selectedProducts.length > 0){
      console.log(selectedProducts);
      changeProductType(value);
    }else{
      toast.error('Please select some products first');

    }
  }
};

const changeProductType = async (type) => {

  try {
      const Body = {
        productIds: selectedProducts,
        type:type
      };
      const res = await post(`api/products/type/change`, Body);
      console.log(res)
      if (res.success) {
        setSelectedProducts([]);
        toast.success('Products type changed successfully');
        fetchData();
      } else {
        toast.success('Failed to make p-pharmacy');
      }
  } catch (error) {
      console.error(error.message);
  }
};
const handleFileChange = (e) => {
  setFile(e.target.files[0]);
};
const handleUpload =async () => {
  const formData = new FormData();
  formData.append('file', file);
  try {
      setIsFileSubmit(true);
      const res = await postFile(`api/products/upload/excel`, formData);
      console.log(res)
      setIsFileSubmit(false)
      if (res.success) {
          toast.success(res.message);
      } else {
          toast.error(res.error);
      }
  } catch (error) {
      setIsFileSubmit(false);
      console.log(error)
      toast.error('An error occurred. Please try again.');
  }


};
  return (
    <>
    <Layout>
      <Toaster 
          position="top-center"
          reverseOrder={false}
      />
      <div className="row mb-9 align-items-center justify-content-between">
        <div className="col-md-4 mb-8 mb-md-0">
          <h2 className="fs-4 mb-0">Product List</h2>
          <Breadcrumbs />
        </div>
        <div className="col-md-8 d-flex flex-wrap justify-content-md-end pt-4">
          {usePermissionCheck(['Upload Excel']) &&
            <button className="btn btn-primary btn-sm me-4" data-bs-toggle="modal" data-bs-target="#excelModel">Uplaod Excel</button>
          }
          {usePermissionCheck(['Ebay Call']) && 
            <button className="btn btn-primary btn-sm  me-4" disabled={isAmazonCalling} onClick={AmazonGetItems}>
              {isAmazonCalling && <i className="fa fa-spinner fa-spin"></i>} {amazonText}
            </button>
          }
          {usePermissionCheck(['Ebay Call']) && 
            <button className="btn btn-primary btn-sm  me-4" disabled={isEbayCalling} onClick={EbayGetItems}>
              {isEbayCalling && <i className="fa fa-spinner fa-spin"></i>} {ebayText}
            </button>
          }
        </div>
      </div>
      <div className="card mb-4 rounded-4 p-7">
        <div className="card-header bg-transparent px-0 pt-0 pb-7">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-2 col-md-4 col-6">
              <select className="form-select" name="type" value={filters.type} onChange={(e)=>{handleBulkAction(e.target.value)}}>
                <option  value='All'> Bulk Action</option>
                <option value='P-Med'> Make P-Pharmacy</option>
                <option value='GSL'> Make GSL</option>
              </select>
            </div>
            <div className="col-lg-10">
              <div className="row justify-content-end flex-nowrap d-flex">
                <div className="col-md-3 col-12 mr-auto mb-md-0 mb-6">
                  <input type="text" placeholder="search..." name="name" value={filters.name} onChange={handleFilterChange} className="form-control bg-input border-0" />
                </div>
                <div className="col-lg-3 col-md-4 col-6">
                  <select className="form-select" name="type" value={filters.type} onChange={handleFilterChange}>
                    <option  value='All'> All Categories</option>
                    <option value='GSL'> GSL</option>
                    <option value='P-Med'> P-Med</option>
                  </select>
                </div>
                <div className="col-lg-3 col-md-4 col-6">
                  <select className="form-select" name="status" value={filters.status} onChange={handleFilterChange}>
                      <option value='All'>Status</option>
                      <option value='Active'>Active</option>
                      <option value='Inactive'>Inactive</option>
                  </select>
                </div>
                <div className="col-lg-3 col-md-4 col-6">
                  <select className="form-select" name="platform" value={filters.platform} onChange={handleFilterChange}>
                    <option  value='All'>Platform</option>
                    <option value='Ebay'>Ebay</option>
                    <option value='GroupOn'>GroupOn</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-0 pt-7 pb-0">
          {isLoading ? (
            <div className='text-center'>
              <Loader />
            </div>
          ):( 
              <div className="">
                <ProductTable products={products} selectedProducts={selectedProducts} handleCheckboxChange={handleCheckboxChange} handleBulkCheckboxChange={handleBulkCheckboxChange} canEditPro={canEditPro} canDeletePro={canDeletePro} canAssignQuestion={canAssignQuestion} />
              </div>
          )}


          {!isLoading && isNotFound && 
            <div className="text-center">
              <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
              <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
            </div>
          }
        </div>
      </div>
      {totalPages && totalPages > 1 && 
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      }

      <div class="modal fade" id="excelModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Upload Excel</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                        <div className="col-12">
                            <input type="file" className="form-control" onChange={handleFileChange}  />
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" onClick={handleUpload} disabled={isFileSubmit}>
                       {isFileSubmit && <i className="fa fa-spinner fa-spin"></i>} Upload
                    </button>
                </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default ProductList