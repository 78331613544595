import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout'
import { get, post } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";

const ProductEdit = () => {
    const backend_ul = process.env.REACT_APP_API_ENDPOINT;
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [isVarSubmit, setIsVarSubmit] = useState(false); 
    const [variations, setVariations] = useState([]); 
    const [postages, setPostages] = useState([]); 
    const [postageCodes, setPostageCodes] = useState([]); 
    const [product, setProduct] = useState({
        title: '',
        type: '',
        enableEbayMessage: false,
        ebayMessageContent: '',
        image:'',
        purchaseLimitDays:'',
        platform:''

    });


    const fetchProduct = async () => {
        try {
          setIsDataLoading(true);
          const res = await get(`api/products/${id}`);
          if (res.success) {
            const { title, type, enableEbayMessage,ebayMessageContent,ebayImage,purchaseLimitDays,platform} = res.data;
            setProduct({ title, type,enableEbayMessage,ebayMessageContent,ebayImage,purchaseLimitDays,platform});
            setVariations(res.variations);
            setPostages(res.postages);
            setPostageCodes(res.postageCodes);
          } else {
            setNotFound(true);
          }
        } catch (error) {
          setNotFound(true);
        } finally {
          setIsDataLoading(false);
        }
      };


    useEffect(() => {
        fetchProduct();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        // Update formData with the new value
        setProduct({
            ...product,
            [name]: type === 'checkbox' ? checked : value // Update checkbox value directly
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('title', product.title);
            formData.append('type', product.type);
            formData.append('enableEbayMessage', product.enableEbayMessage);
            formData.append('ebayMessageContent', product.ebayMessageContent);
            formData.append('purchaseLimitDays', product.purchaseLimitDays);
    
            // Append the image file if it exists
            if (product.image) {
                formData.append('image', product.image);
            }
            const res = await post(`api/products/update/${id}`, formData,true);
            if (res.success) {
                const { title, type, enableEbayMessage,ebayMessageContent,ebayImage,purchaseLimitDays} = res.data;
                setProduct({ title, type,enableEbayMessage,ebayMessageContent,ebayImage, purchaseLimitDays});
                toast.success(res.message);
                setIsLoading(false);

            } else {
                toast.error(res.error);
              setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };
    const handleVarInputChange = (index, field, value) => {
        const newVariations = [...variations];
        newVariations[index][field] = value;
        setVariations(newVariations);
    };
    const handleVarPostageChange = (varIndex, postageIndex, newValue, name) => {
        const updatedVariations = [...variations];
        if (!updatedVariations[varIndex].postage) {
            updatedVariations[varIndex].postage = [];
        }
        updatedVariations[varIndex].postage[postageIndex] = { 
            ...updatedVariations[varIndex].postage[postageIndex], 
            name:name,
            code: newValue 
        };
        setVariations(updatedVariations);
    };

    const handleVariationSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsVarSubmit(true);
            const Body = {
                variations: variations.map(v => ({
                  SKU: v.SKU,
                  name: v.name,
                  format: v.format,
                  weight: v.weight,
                  length: v.length,
                  width: v.width,
                  height: v.height,
                  limit:v.limit,
                  postage: v.postage ? v.postage.map(p => ({
                    name: p.name, 
                    code: p.code || ''
                  })) : []
                }))
            };
            const res = await post(`api/products/variations/update/${id}`, Body);
            setIsVarSubmit(false);
            console.log(res)
            if (res.success) {
                toast.success(res.message);

            } else {
                toast.error(res.error);
            }
        } catch (error) {
            setIsVarSubmit(false);
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };

  return (
    <>
    <Layout>
        <div>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-sm-6 mb-8 mb-sm-0">
 
                        <h2 className="fs-4 mb-0 bd-heading">
                            Edit Product
                        </h2>
                        <Breadcrumbs />

                    </div>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-12"> 
                            <div className="card mb-8 rounded-4">
                                <div className="card-body p-7">
                                {isDataLoading &&
                                    <div className='text-center'>
                                        <Loader />
                                    </div>
                                }
                                {!isDataLoading && !isNotFound &&
                                    <form className="form-border-1" onSubmit={handleSubmit}>
                                        <div className="mb-8">
                                            <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                title
                                            </label>
                                            <input type="text" name="title" placeholder="Type here" className="form-control" value={product.title} onChange={handleInputChange} id="product_title" required />
                                        </div>
                                        <div className="mb-8">
                                            <label className="mb-4 fs-13px ls-1 fw-bold text-uppercase" htmlFor="currency">Choose Product Type</label>
                                            <select className="form-select" name="type"   value={product.type} onChange={handleInputChange} required>
                                                <option defaultValue="">Choose</option>
                                                <option value="GSL">GSL</option>
                                                <option value="P-Med">P-Med</option>
                                            </select>
                                        </div>
                                        
                                        <div className="mb-4">
                                            <div className="d-flex align-items-center">
                                                <input className="form-check-input rounded-0 ms-0" type="checkbox" name="enableEbayMessage" checked={product.enableEbayMessage} onChange={handleInputChange} />
                                                <div style={{ marginLeft: '10px' }}>
                                                    <label htmlFor="enableEbayMessage">Enable Message on Purchase</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-8">
                                            <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                 Message Content
                                            </label>
                                            <textarea className="form-control" rows={7} placeholder="write your message here.." value={product.ebayMessageContent} onChange={handleInputChange} name="ebayMessageContent"  />
                                        </div>
                                        <div className="mb-8">
                                            <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                Message Attachment
                                            </label>
                                            <input type="file" className="form-control" onChange={(e) => setProduct({ ...product, image: e.target.files[0] })} />
                                        </div>
                                        {product?.ebayImage?.public_id &&
                                            <img src={`${backend_ul}/uploads/${product.ebayImage.public_id}`} alt="Product Image" style={{ maxWidth: '100%', height: 'auto' }}/>
                                        }
                                        <div className="mb-8">
                                            <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                Purchase Limit Days
                                            </label>
                                            <input type="number" name="purchaseLimitDays" placeholder="Days limit" className="form-control" value={product.purchaseLimitDays} onChange={handleInputChange} id="product_title"  />
                                        </div>
                                        
                                        
                                        <div className="col-sm-12 d-flex flex-wrap justify-content-sm-end">
                                            <button type="submit" className="btn btn-primary"  disabled={isLoading}>
                                                {isLoading && <i className="fa fa-spinner fa-spin"></i>}   Update
                                            </button>
                                        </div>
                                    </form>
                                }
                                {!isDataLoading && isNotFound && 
                                    <div className="text-center">
                                    <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
                                    <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                                    </div>
                                }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {variations && variations.length > 0 &&           
                    <form onSubmit={handleVariationSubmit} className="form-border-1">
                        <div className="row mt-4">
                            {variations.map((variation, index) => (
                                <div className="col-lg-4" key={index}>
                                    <div className="card mb-8 rounded-4">
                                        <div className="card-header">
                                            <h6>{variation.SKU}</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-8">
                                                <label htmlFor={`name_${index}`} className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                    Name
                                                </label>
                                                <input type="text" name="name" placeholder="Type here" className="form-control"  id={`name_${index}`} 
                                                    value={variation.name || ''} onChange={(e) => handleVarInputChange(index, 'name', e.target.value)} />
                                            </div>
                                            <div className="mb-8">
                                                <label className="mb-4 fs-13px ls-1 fw-bold text-uppercase" htmlFor={`format_${index}`}>Choose Format</label>
                                                <select className="form-select" name="format" value={variation.format || ''} onChange={(e) => handleVarInputChange(index, 'format', e.target.value)} required>
                                                    <option value="">Choose</option>
                                                    <option value="Letter">Letter</option>
                                                    <option value="Large letter">Large letter</option>
                                                    <option value="Small parcel">Small parcel</option>
                                                    <option value="Medium parcel">Medium parcel</option>
                                                    <option value="Parcel">Parcel</option>
                                                    <option value="Documents">Documents</option>
                                                </select>
                                            </div>
                                            <div className="mb-8">
                                                <label htmlFor={`weight_${index}`} className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                        Weight (<span style={{textTransform:'lowercase'}}>g</span>)
                                                </label>
                                                <input type="text" name="weight" placeholder="Type here" className="form-control" value={variation.weight || ''} onChange={(e) => handleVarInputChange(index, 'weight', e.target.value)}  required />
                                            </div>
                                            <div className="mb-8">
                                                <label htmlFor={`length_${index}`} className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                        Length (<span style={{textTransform:'lowercase'}}>g</span>)
                                                </label>
                                                <input type="text" name="length" placeholder="Type here" className="form-control" value={variation.length || ''} onChange={(e) => handleVarInputChange(index, 'length', e.target.value)}  required />
                                            </div>
                                            <div className="mb-8">
                                                <label htmlFor={`width_${index}`} className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                        Width (<span style={{textTransform:'lowercase'}}>g</span>)
                                                </label>
                                                <input type="text" name="width" placeholder="Type here" className="form-control" value={variation.width || ''} onChange={(e) => handleVarInputChange(index, 'width', e.target.value)}  required />
                                            </div>
                                            <div className="mb-8">
                                                <label htmlFor={`height_${index}`} className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                        Height (<span style={{textTransform:'lowercase'}}>g</span>)
                                                </label>
                                                <input type="text" name="height" placeholder="Type here" className="form-control" value={variation.height || ''} onChange={(e) => handleVarInputChange(index, 'height', e.target.value)}  required />
                                            </div>
                                            <div className="mb-8">
                                                <label htmlFor={`limit_${index}`} className="mb-4 fs-13px ls-1 fw-bold text-uppercase"  >
                                                        Limit
                                                </label>
                                                <input type="number" name="limit" placeholder="Type here" className="form-control" value={variation.limit || ''} onChange={(e) => handleVarInputChange(index, 'limit', e.target.value)}  />
                                            </div>
                                            {postages && postages.length > 0 && 
                                                <>
                                                {postages.map((postage, pIndex) => (
                                                    <div className="mb-8" key={`${index}_${pIndex}`}>
                                                        <label
                                                            htmlFor={`postage_${index}_${pIndex}`}
                                                            className="mb-4 fs-13px ls-1 fw-bold text-uppercase"
                                                        >
                                                            {postage.name} <span style={{fontSize:'10px'}}>({postage.type})</span>
                                                        </label>
                                                        <select className="form-select" name={`postageCode_${index}_${pIndex}`} 
                                                            value={variation.postage?.[pIndex]?.code || ''} 
                                                            onChange={(e) => handleVarPostageChange(index, pIndex, e.target.value, postage.name)} 
                                                        >
                                                            <option value="">Choose</option>
                                                            {postageCodes.map((item, cIndex) => (
                                                                <option key={cIndex} value={item.code}>{item.code}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ))}
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-12 text-center">
                            <button type="submit" className="btn btn-primary" disabled={isVarSubmit}>
                             {isVarSubmit &&  <i className="fa fa-spinner fa-spin"></i>} {isVarSubmit ? 'Please Wait':'Update Variations'} 
                            </button>
                        </div>
                    </form>
                }
            </div>
        </div>
    </Layout>
    </>
  )
}

export default ProductEdit