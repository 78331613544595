import React from 'react';
import { Link } from "react-router-dom";
import { Tag } from 'antd';
function truncateText(text, wordLimit) {
    if (typeof text !== 'string') return '';


    if (text.length > wordLimit) {
        return text.slice(0, wordLimit) + '...';
    }
    return text;
}
const ProductTable = ({ products,selectedProducts,handleCheckboxChange, handleBulkCheckboxChange, canEditPro,canDeletePro,canAssignQuestion}) => {
    const isAllSelected = products.length > 0 && selectedProducts.length === products.length;
    return (
        <table className="table table-hover align-middle table-nowrap mb-0">
            <thead>
                <tr>
                    <th>
                        <div className="form-check">
                                <input className="form-check-input rounded-0 ms-0" type="checkbox" onChange={handleBulkCheckboxChange} checked={isAllSelected}  style={{borderColor:'black'}}/>
                                <label className="form-check-label" />
                        </div>
                    </th>
                    <th>Product</th>
                    <th>Platform</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, index) => (
                    <tr key={index}>
                        <td className="text-center">
                            <div className="form-check">
                                <input className="form-check-input rounded-0 ms-0" type="checkbox" id={`transactionCheck-${index}`} value={product._id} onChange={handleCheckboxChange} checked={selectedProducts.includes(product._id)} />
                                <label className="form-check-label" htmlFor={`transactionCheck-${index}`} />
                            </div>
                        </td>
                        <td>
                            <div className="d-flex align-items-center flex-nowrap">
                                <div title={product.title}>
                                    <img src={product.image} data-src={product.dataSrc} alt={product.name} className="lazy-image" style={{width:'50px',height:'60px'}} />
                                </div>
                                <div title={product.title} className="ms-6">
                                    <p className="fw-semibold text-body-emphasis mb-0">
                                         {truncateText(product.title, 25)}
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td>
                            {/* <Tag color={product.platform === "Ebay" ? "gold":"green"}>{product.platform}</Tag> */}
                            <img 
                            src={`/assets/images/${product.platform === 'Ebay' ? 'ebay-icon.png' : product.platform === 'Amazon' ? 'amz-icon.png' : 'go-icon.png'}`}
                            alt="Platform Image"
                            style={{ width: product.platform === 'Ebay' ? '40px' : '60px' }}
                            />
                        </td>
                        <td>£{product.price}</td>
                        <td>
                            <span className={`badge rounded-lg rounded-pill alert py-3 px-4 mb-0 alert-${product.status === 'Active' ? 'success' : 'danger'} border-0 text-capitalize fs-12`}>{product.status}</span>
                        </td>
                        <td>{product.type ? product.type :'Nill'}</td>
                        <td className="text-center">
                            <div className="d-flex flex-nowrap justify-content-center">
                                <div class="dropdown no-caret">
                                    <a href="javascript:;" data-bs-toggle="dropdown" class="dropdown-toggle btn btn-outline-primary btn-xs hover-white btn-hover-bg-primary py-4 px-5">
                                        <i class="far fa-ellipsis-h"></i> 
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end m-0">
                                        {product.type && product.type === 'P-Med' && canAssignQuestion && 
                                            <>
                                            <Link to={`/products/assign-question/${product._id}`} class="dropdown-item">Assign Questions</Link>
                                            </>
                                        }
                                        {canEditPro &&
                                            <Link to={`/products/edit/${product._id}`} class="dropdown-item" href="#">Edit info</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProductTable;
